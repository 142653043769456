export default function Landing({}) {

    return (
        <>
        <main className="bg-primary bg-cover bg-center bg-no-repeat h-screen flex flex-col justify-center items-center">
            <h1 className="text-4xl font-bold text-white">Aldin FC</h1>
            <img src="https://server.pabloeguilaz.es/aldinfc/static/escudo.png" alt="Aldin FC" className="size-[400px]" />
        </main>
        </>
    )

}