import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, useParams } from 'react-router-dom';

import { Toaster } from 'sonner'

import { Footer, Header, Sponsors, MobileHeader } from "./components";
import { Inicio, Jugadores, PageNotFound, Partidos, Login, Comunicado, Changelog, Change, Landing } from "./pages";

import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"

import Jugador from "./pages/Jugador";
import Galeria from "./pages/Galeria";

import { useStateContext } from "./functions/ContextProvider";
import EditarComunicado from "./admin/EditarComunicado";
import Perfil from "./admin/Perfil";
import Store from "./pages/Store";

function App() {

  const [viewSelect, setViewSelect] = useState("Aldin FC")
  const location = useLocation();

  const { user, setUser } = useStateContext();

  const setView = (view) => {
    setViewSelect(view)
    window.document.title = `Aldin FC | ${view}`
  }

  useEffect(() => {

    window.document.title = "Aldin FC"

    const params = new URLSearchParams(location.search);
    const tokenParam = params.get('token');
    
    if (tokenParam != null) {
      localStorage.setItem('token', tokenParam);
    }

  }, []); // El array vacío asegura que el efecto solo se ejecute una vez

  return (
    <>
      <main className="bg-body dark:bg-body_dark text-black dark:text-white">
        <Landing />
      </main>
      <Analytics />
      <SpeedInsights /> 
    </>
  );
}

export default App; 
